import {
  LOGIN_V2,
  VALIDATE_2FA,
  LOGIN_V1,
  LOGOUT,
  RESET_PASSWORD,
  SET_PASSWORD,
  GET_PROFILE,
  UPDATE_PROFILE
} from "./actionTypes";

export function loginV2(email, password) {
  return { type: LOGIN_V2, payload: {email, password} }
}

export function validate2FA(tempToken, sigResponse) {
  return { type: VALIDATE_2FA, payload: {"temp-token": tempToken, sig_response: sigResponse}}
}

export function loginV1(email, password) {
  return { type: LOGIN_V1, payload: {email, password} }
}

export function logout() {
  return { type: LOGOUT, payload: {} }
}

export function resetPassword(email) {
  return { type: RESET_PASSWORD, payload: { email }}
}

export function setPassword(password, token) {
  return { type: SET_PASSWORD, payload: { password, token }}
}

export function getProfile() {
  return { type: GET_PROFILE, payload: {}}
}

export function updateProfile(profile) {
  return { type: UPDATE_PROFILE, payload: profile }
}