import React from "react"
import pathOr from "ramda/src/pathOr";
import { connect } from "react-redux";
import PeopleIcon from '@material-ui/icons/People';
import IconBox from "../../components/IconBox";
import EnhancedTable from "./Table"

const mapStateToProps = state => ({
  clubFoot: pathOr({}, ["clubFoot", "users"], state)
});

export default connect(mapStateToProps)(({clubFoot}) => {
  let results = clubFoot.results || []
  return (
    <>
      <div>
        <IconBox
          IconComponent={<PeopleIcon />}
          value={results.length || 0}
          label="Patients"
        />
        <EnhancedTable 
          rows={clubFoot.results ? clubFoot.results.slice(0, -1) : []}
          loading={clubFoot.loading}
        />
      </div>
    </>
  )
})
