import Swal from "sweetalert2";

export const validateEmail = email => {
  if(email === undefined) return true;
  /* eslint-disable */
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePassword = password => {
  if(password === undefined) return true;
  /* eslint-disable */
  return password.length > 8;
};

export const showLoading = (text) => {
  Swal.fire({
    type: 'info',
    title: '',
    text,
    allowOutsideClick: false
  });

  Swal.showLoading();
}

export const hideLoading = () => {
  if (Swal.isVisible()) {
    Swal.hideLoading();
    Swal.close();
  }
}
