import React from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import { resetAdminPassword } from '../../actions/admin.js'
import { validatePassword } from "../../utils/helpers";

const CheckboxContainer = styled.div`
  display: flex;
`

const useStyles = makeStyles(theme => ({
  textField: {
    width: 250, 
  },
  formControl: {
    marginTop: 16,
  },
  content: {
    overflow: 'hidden',
  }
}));

const mapDispatchToProps = dispatch => {
  return {
    resetAdminPassword: (id, password) => dispatch(resetAdminPassword(id, password))
  }
}

export default connect(null, mapDispatchToProps)((props) => {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    password: '',
    passwordError: false
  });

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSubmit = event => {
    if (validatePassword(values.password)) {
      setValues({ ...values, passwordError: false, password: '' });      
      props.resetAdminPassword(props.id, values.password)
      props.onClose();
    } else {
      setValues({ ...values, passwordError: true });      
    }
  }

  return (
    <div>
      <input style={{opacity: 0, position: 'absolute'}} />
      <input type="password" style={{opacity: 0, position: 'absolute'}} />
      <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Reset Admin Password</DialogTitle>
        <DialogContent className={classes.content}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                id="filled-password"
                className={classes.textField}
                label="New Password"
                value={values.password}
                onChange={handleChange('password')}
                margin="normal"
                variant="filled"
                error={values.passwordError}
                helperText={values.passwordError ? "Must be at least 9 characters" : ''}
              /> 
            </Grid>
          </Grid>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="secondary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
})
