import { getRequest, postRequest, postMultipartRequest, putRequest, deleteRequest, setPasswordRequest } from '../httpHelper.js';

const API = {
  
  loginV2(action) {
    return postRequest(`${process.env.REACT_APP_API_V2_URL}admin/auth/login/`, action.payload)
  },
  
  validate2FA(action) {
    return postMultipartRequest(`${process.env.REACT_APP_API_V2_URL}admin/verifyuser/`, action.payload)
  },

  loginV1(action) {
    return postRequest(`${process.env.REACT_APP_API_URL}auth/login/`, action.payload)
  },

  getProfile(action) {
    return getRequest(`${process.env.REACT_APP_API_URL}admin/me/`)
  },

  updateProfile(action) {
    return putRequest(`${process.env.REACT_APP_API_URL}admin/me/`, action.payload)
  },

  resetPassword(action) {
    return postRequest(`${process.env.REACT_APP_API_URL}admin/forgotpassword/`, action.payload)
  },

  setPassword(action) {
    return setPasswordRequest(`${process.env.REACT_APP_API_URL}admin/setpassword/`, action.payload)
  },
    
  getAdmins(action) {
    return getRequest(`${process.env.REACT_APP_API_URL}admin/profile/`)
  },

  getAdmin(action) {
    return getRequest(`${process.env.REACT_APP_API_URL}admin/profile/${action.payload.id}`)
  },

  updateAdmin(action) {
    return putRequest(`${process.env.REACT_APP_API_URL}admin/profile/${action.payload.id}/`, action.payload.admin)
  },

  addAdmin(action) {
    return postRequest(`${process.env.REACT_APP_API_V2_URL}admin/signup/`, action.payload)
  },

  deleteAdmin(action) {
    return deleteRequest(`${process.env.REACT_APP_API_URL}admin/profile/${action.payload.id}`)
  },

  resetAdminPassword(action) {
    return putRequest(`${process.env.REACT_APP_API_URL}admin/resetpassword/${action.payload.id}/`, {password: action.payload.password})
  },

  getDashboard(action) {
    return getRequest(`${process.env.REACT_APP_API_URL}user/`)
  },

  exportUsers(action) {
    return getRequest(`${process.env.REACT_APP_API_URL}user/export/`)
  },

  getClubFootUsers(action) {
    return getRequest(`${process.env.REACT_APP_API_URL}user/?of_type=clubfoot`)
  },

  getClubFootUserDetails(action) {
    return getRequest(`${process.env.REACT_APP_API_URL}user/${action.payload.id}/?of_type=clubfoot`)
  },

  getClubFootDaily(action) {
    return getRequest(`${process.env.REACT_APP_API_URL}user/dailyreadings/${action.payload.id}/?of_type=clubfoot`)
  },

  getClubFootWeekly(action) {
    return getRequest(`${process.env.REACT_APP_API_URL}user/weeklyreadings/${action.payload.id}/?of_type=clubfoot`)
  },

  getClubFootMonthly(action) {
    return getRequest(`${process.env.REACT_APP_API_URL}user/monthlyreadings/${action.payload.id}/?of_type=clubfoot`)
  },

  updateClubFootUser(action) {
    return putRequest(`${process.env.REACT_APP_API_URL}user/${action.payload.id}/?of_type=clubfoot`, action.payload)
  },

  deleteClubFootUser(action) {
    return deleteRequest(`${process.env.REACT_APP_API_URL}user/${action.payload.id}/?of_type=clubfoot`)
  },

  getScoliosisUsers(action) {
    return getRequest(`${process.env.REACT_APP_API_URL}user/?of_type=scoliosis`)
  },

  getScoliosisUserDetails(action) {
    return getRequest(`${process.env.REACT_APP_API_URL}user/${action.payload.id}/?of_type=scoliosis`)
  },

  getScoliosisDaily(action) {
    return getRequest(`${process.env.REACT_APP_API_URL}user/dailyreadings/${action.payload.id}/?of_type=scoliosis`)
  },

  getScoliosisWeekly(action) {
    return getRequest(`${process.env.REACT_APP_API_URL}user/weeklyreadings/${action.payload.id}/?of_type=scoliosis`)
  },

  getScoliosisMonthly(action) {
    return getRequest(`${process.env.REACT_APP_API_URL}user/monthlyreadings/${action.payload.id}/?of_type=scoliosis`)
  },

  updateScoliosisUser(action) {
    return putRequest(`${process.env.REACT_APP_API_URL}user/${action.payload.id}/?of_type=scoliosis)`, action.payload)
  },

  deleteScoliosisUser(action) {
    return deleteRequest(`${process.env.REACT_APP_API_URL}user/${action.payload.id}/?of_type=scoliosis`)
  }

}

export default API;
