import React from "react"
import { connect } from "react-redux";
import pathOr from "ramda/src/pathOr";
import styled from "styled-components"
import InfoBox from "./InfoBox";
import Graphs from "../../app/Graphs";
import PatientStatusBox from "../../../components/PatientStatusBox";
import { getClubFootUserDetails, updateClubFootUser, deleteClubFootUser } from '../../../actions/clubFoot.js'

const InfoBoxContainer = styled.div`
  margin-top: 25px;
`

const mapStateToProps = state => ({
  user: pathOr({}, ["clubFoot", "user"], state),
  users: pathOr({}, ["clubFoot", "users"], state),
  daily: pathOr({}, ["clubFoot", "daily"], state),
  weekly: pathOr({}, ["clubFoot", "weekly"], state),
  monthly: pathOr({}, ["clubFoot", "monthly"], state),
});

const mapDispatchToProps = dispatch => {
  return {
    getClubFootUserDetails: (id) => dispatch(getClubFootUserDetails(id)),
    updateClubFootUser: (user) => dispatch(updateClubFootUser(user)),
    deleteClubFootUser: (id) => dispatch(deleteClubFootUser(id))
  }
}

const ClubFootPatient = (props) => {
  React.useEffect(() => {
    props.getClubFootUserDetails(props.match.params.id)
  }, [props.match.params.id])

  const updateUser = (values) => {
    let user = {
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      contact_number: values.contact_number,
      sensor_id: values.sensor_id,
      id: values.user_id
    }
    props.updateClubFootUser(user)
  }

  return (
    <>
      <PatientStatusBox users={props.users} userId={props.match.params.id} user={props.user}/>
      <InfoBoxContainer>
        <InfoBox user={props.user} updateUser={updateUser} deleteUser={props.deleteClubFootUser}/>
      </InfoBoxContainer>
      <InfoBoxContainer>
        <Graphs daily={props.daily} weekly={props.weekly} monthly={props.monthly} users={props.users} userId={props.match.params.id}/>
      </InfoBoxContainer>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ClubFootPatient);