import axios from 'axios';

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.withCredentials = true

function config() {
  return {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  };
}

function multipartConfig() {
  return {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
}

function handleError(err) {
  throw err.response;
}

function handleResponse(response) {
  return response.data;
}

function handleResponseStatus(response) {
  return response.status
}

export const getToken = () => localStorage.getItem("authToken")

export const setAuth = token => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `token ${token}`;
    localStorage.setItem("authToken", token);
  }
};

export const clearAuth = () => {
  delete axios.defaults.headers.common["Authorization"];
  localStorage.removeItem("authToken");
}

export function setPasswordRequest(url, data) {
  clearAuth()
  return axios
    .post(url, data, config())
    .then(handleResponse)
    .catch(handleError);
}

export function getRequest(url) {
  return axios
    .get(url, config())
    .then(handleResponse)
    .catch(handleError);
}

export function postRequest(url, data) {
  return axios
    .post(url, data, config())
    .then(handleResponse)
    .catch(handleError);
}

export function postMultipartRequest(url, data) {
  let formData = new FormData();
  for (var key in data) {
    formData.append(key, data[key])
  }
  return axios
    .post(url, formData, multipartConfig())
    .then(handleResponse)
    .catch(handleError)
}

export function putRequest(url, data) {
  return axios
    .put(url, data, config())
    .then(handleResponse)
    .catch(handleError);
}

export function putMultipartRequest(url, data) {
  let formData = new FormData();
  for (var key in data) {
    formData.append(key, data[key])
  }
  return axios
    .put(url, formData, multipartConfig())
    .then(handleResponse)
    .catch(handleError)
}

export function deleteRequest(url) {
  return axios
    .delete(url, config())
    .then(handleResponse)
    .catch(handleError);
}