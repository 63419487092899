import { 
  GET_CLUBFOOT, 
  GET_CLUBFOOT_USER, 
  UPDATE_CLUBFOOT_USER, 
  DELETE_CLUBFOOT_USER,
  GET_CLUBFOOT_DAILY,
  GET_CLUBFOOT_WEEKLY,
  GET_CLUBFOOT_MONTHLY
} from "./actionTypes";

export function getClubFootUsers() {
  return { type: GET_CLUBFOOT, payload: {}}
}

export function getClubFootUserDetails(id) {
  return { type: GET_CLUBFOOT_USER, payload: {id}}
}

export function updateClubFootUser(user) {
  return { type: UPDATE_CLUBFOOT_USER, payload: {...user}}
}

export function deleteClubFootUser(id) {
  return { type: DELETE_CLUBFOOT_USER, payload: {id}}
}

export function getClubFootDaily(id) {
  return { type: GET_CLUBFOOT_DAILY, payload: {id}}
}

export function getClubFootWeekly(id) {
  return { type: GET_CLUBFOOT_WEEKLY, payload: {id}}
}

export function getClubFootMonthly(id) {
  return { type: GET_CLUBFOOT_MONTHLY, payload: {id}}
}