import { 
  GET_SCOLIOSIS, 
  GET_SCOLIOSIS_USER, 
  UPDATE_SCOLIOSIS_USER, 
  DELETE_SCOLIOSIS_USER,
  GET_SCOLIOSIS_DAILY,
  GET_SCOLIOSIS_WEEKLY,
  GET_SCOLIOSIS_MONTHLY
} from "./actionTypes";

export function getScoliosisUsers() {
  return { type: GET_SCOLIOSIS, payload: {}}
}

export function getScoliosisUserDetails(id) {
  return { type: GET_SCOLIOSIS_USER, payload: {id}}
}

export function updateScoliosisUser(user) {
  return { type: UPDATE_SCOLIOSIS_USER, payload: {...user}}
}

export function deleteScoliosisUser(id) {
  return { type: DELETE_SCOLIOSIS_USER, payload: {id}}
}

export function getScoliosisDaily(id) {
  return { type: GET_SCOLIOSIS_DAILY, payload: {id}}
}

export function getScoliosisWeekly(id) {
  return { type: GET_SCOLIOSIS_WEEKLY, payload: {id}}
}

export function getScoliosisMonthly(id) {
  return { type: GET_SCOLIOSIS_MONTHLY, payload: {id}}
}