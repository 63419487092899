import React from "react"
import styled from "styled-components"
import Tabs from "../../components/tabs/Tabs";
import Tab from "../../components/tabs/Tab";
import TabPanel from "../../components/tabs/TabPanel";
import { palette } from "../../styles/variables";
import Graph from './Graph'

const Container = styled.div`
  border-radius: 4px;
  border: solid 1px ${palette.border};
  background-color: white;
  position: relative;
`

const Graphs = (props) => {
  const [tabValue, setTabValue] = React.useState(0);

  function handleTabChange(event, newValue) {
    setTabValue(newValue);
  }

  return (
    <Container>
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="Parent/Patient Info Box">
        <Tab label="Daily" />
        <Tab label="Weekly" />
        <Tab label="Monthly" />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <Graph scale="daily" data={props.daily} users={props.users} userId={props.userId}/>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Graph scale="weekly" data={props.weekly} users={props.users} userId={props.userId}/>
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Graph scale="monthly" data={props.monthly} users={props.users} userId={props.userId}/>
      </TabPanel>
    </Container>
  )
}

export default Graphs