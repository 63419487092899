export default {
  auth: {
    token: null,
    email: null,
    firstname: null,
    lastname: null,
    speciality: null,
    created_at: null,
    loading: false,
    error: false,
    tempToken: "",
    host: "",
    sigRequest: "",
    loginSucceeded: false
  },
  dashboard: {
    loading: true,
    results: [],
  },
  clubFoot: {
    user: {
      parent: {}
    },
    daily: [],
    weekly: [],
    monthly: [],
    loading: true,
    results: []

  },
  scoliosis: {
    user: {
      parent: {},
      patient: {}
    },
    daily: [],
    weekly: [],
    monthly: [],
    loading: true,
    results: []
  },
  admin: {
    loading: true
  },
};
