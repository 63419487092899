import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Route, Switch, Redirect } from "react-router-dom";
import Dashboard from "../dashboard";
import Scoliosis from "../scoliosis";
import ScoliosisPatient from "../scoliosis/patient";
import ClubFoot from "../club-foot";
import ClubFootPatient from "../club-foot/patient";
import Doctors from "../doctors";
import MyProfile from "../doctors/profile";
import AdminProfile from "../doctors/profile/adminProfile";
import Sidebar from "./Sidebar";
import { palette } from "../../styles/variables";
import { getAdmins } from "../../actions/admin";
import { getProfile } from "../../actions/auth";
import { getDashboardUsers } from "../../actions/dashboard";
import { getClubFootUsers } from "../../actions/clubFoot";
import { getScoliosisUsers, getScoliosisUserDetails } from "../../actions/scoliosis";

const Container = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Main = styled.div`
  flex: 1;
  padding: 32px;
  margin-left: 255px;
  background-color: ${palette.background};
`;

const mapDispatchToProps = dispatch => ({
  getAdmins: () => dispatch(getAdmins()),
  getProfile: () => dispatch(getProfile()),
  getDashboardUsers: () => dispatch(getDashboardUsers()),
  getClubFootUsers: () => dispatch(getClubFootUsers()),
  getScoliosisUsers: () => dispatch(getScoliosisUsers()),
  getScoliosisUserDetails: (id) => dispatch(getScoliosisUserDetails(id))
});

export default connect(
  null,
  mapDispatchToProps
)(({ getAdmins, getProfile, getDashboardUsers, getClubFootUsers, getScoliosisUsers, getScoliosisUserDetails }) => {
  useEffect(() => {
    getAdmins();
    getProfile();
    getDashboardUsers();
    getClubFootUsers();
    getScoliosisUsers();
  }, [
    getAdmins,
    getProfile,
    getDashboardUsers,
    getClubFootUsers,
    getScoliosisUsers
  ])
  
  return (
    <Container>
      <Sidebar />
      <Main>
        <Switch>
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/scoliosis" component={Scoliosis} />
          <Route exact path="/scoliosis/:id" 
                 render={(props) => <ScoliosisPatient {...props} getScoliosisUserDetails={getScoliosisUserDetails} /> } />
          <Route exact path="/clubfoot" component={ClubFoot} />
          <Route exact path="/clubfoot/:id" component={ClubFootPatient} />
          <Route exact path="/admins" component={Doctors} />
          <Route exact path="/admins/profile" component={MyProfile} />
          <Route exact path="/admins/:id" component={AdminProfile} />
          <Redirect exact path="/" to="/dashboard" />
        </Switch>
      </Main>
    </Container>
  );
});
