import React, { PureComponent } from 'react';
import toPairs from 'lodash/toPairs'
import sortBy from 'lodash/sortBy'
import {
  BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, ReferenceLine
} from 'recharts';
import find from 'lodash/find'

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

const dayNames = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']

const getTwoDigit = (num) => {
  if (num < 10) return '0' + num
  else return '' + num
}

const getMonday = (d) => {
  d = new Date(d);
  var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6:1); 
  return new Date(d.setDate(diff));
}

export default class Graph extends PureComponent {
  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/90v76x08/';

  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      formattedData: []
    }
  }

  componentDidMount() {
    this.formatData()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data != this.props.data)
      this.formatData()
  }

  formatData() {
    if (this.props.scale == 'monthly') {
      this.formatMonthly(this.props.data)
    } else if (this.props.scale == 'weekly') {
      this.formatWeekly(this.props.data)
    } else {
      this.formatDaily(this.props.data)
    }
  }

  formatMonthly(data) {
    if (!data || data.length == 0) {
      this.setState({dataLoaded: true})
      return 
    }
    let months = {}
    for (let i = 0; i < data.length; i++) {
      let dateKey = Object.keys(data[i])[0]
      let date = new Date(dateKey)
      let monthKey = `${date.getFullYear()}${getTwoDigit(date.getMonth() + 1)}`
      let keys = Object.keys(months)
      if (!keys.includes(monthKey)) {
        if (keys.length <= 14) //new month added to object, up to 14 months
          months[monthKey] = {
            name: monthNames[(date.getMonth())],
            totalD: data[i][dateKey]['day'],
            totalE: data[i][dateKey]['evening'],
            totalS: data[i][dateKey]['sleep'],
            number: 1,
            Day: data[i][dateKey]['day'],
            Evening: data[i][dateKey]['evening'],
            Sleep: data[i][dateKey]['sleep'],
          }
      } else {
        months[monthKey]['totalD'] += data[i][dateKey]['day']
        months[monthKey]['totalE'] += data[i][dateKey]['evening']
        months[monthKey]['totalS'] += data[i][dateKey]['sleep']
        months[monthKey]['number'] += 1
        months[monthKey]['Day'] = Math.round(months[monthKey]['totalD'] / months[monthKey]['number'] * 10) / 10
        months[monthKey]['Evening'] = Math.round(months[monthKey]['totalE'] / months[monthKey]['number'] * 10) / 10
        months[monthKey]['Sleep'] = Math.round(months[monthKey]['totalS'] / months[monthKey]['number'] * 10) / 10
        months[monthKey]['Total'] = Math.round((months[monthKey]['Day'] + months[monthKey]['Evening'] + months[monthKey]['Sleep']) * 10 )/10
      }
    }
    let pairs = sortBy(toPairs(months), 0)
    this.setState({
      formattedData: pairs.map(x => x[1]),
      dataLoaded: true
    })
  }
  
  formatWeekly(data) {
    if (!data || data.length == 0) {
      this.setState({dataLoaded: true})
      return 
    }
    let weeks = {}
    for (let i = 0; i < data.length; i++) {
      let dateKey = Object.keys(data[i])[0]
      let date = new Date(dateKey)
      let Monday = getMonday(date)
      let weekKey = `${Monday.getFullYear()}${getTwoDigit(Monday.getMonth() + 1)}${getTwoDigit(Monday.getDate())}`
      let keys = Object.keys(weeks)
      if (!keys.includes(weekKey)) {
        if (keys.length <= 14) //new week added to object, up to 14 weeks
          weeks[weekKey] = {
            name: `${Monday.getMonth() + 1}/${Monday.getDate()}`,
            totalD: data[i][dateKey]['day'],
            totalE: data[i][dateKey]['evening'],
            totalS: data[i][dateKey]['sleep'],
            number: 1,
            Day: data[i][dateKey]['day'],
            Evening: data[i][dateKey]['evening'],
            Sleep: data[i][dateKey]['sleep'],
          }
      } else {
        weeks[weekKey]['totalD'] += data[i][dateKey]['day']
        weeks[weekKey]['totalE'] += data[i][dateKey]['evening']
        weeks[weekKey]['totalS'] += data[i][dateKey]['sleep']
        weeks[weekKey]['number'] += 1
        weeks[weekKey]['Day'] = Math.round(weeks[weekKey]['totalD'] / 7 * 10) / 10
        weeks[weekKey]['Evening'] = Math.round(weeks[weekKey]['totalE'] / 7 * 10) / 10
        weeks[weekKey]['Sleep'] = Math.round(weeks[weekKey]['totalS'] / 7 * 10) / 10
        weeks[weekKey]['Total'] = Math.round((weeks[weekKey]['Day'] + weeks[weekKey]['Evening'] + weeks[weekKey]['Sleep']) * 10) / 10
      }
    }
    let pairs = sortBy(toPairs(weeks), 0)
    this.setState({
      formattedData: pairs.map(x => x[1]),
      dataLoaded: true
    })
  }

  formatDaily(data) {
    // console.log('data', data)
    if (!data || data.length == 0 || !Array.isArray(data)) {
      this.setState({dataLoaded: true})
      return 
    }
    let days = data.map(obj => {
      let dateKey = Object.keys(obj)[0]
      let date = new Date(dateKey + 'T00:00:00')
      // console.log('datekey', dateKey, 'date', date, 'name', date.getDate())
      let day = {
        name: `${date.getMonth() + 1}/${date.getDate()}`,
        Day: Math.round(obj[dateKey]['day'] * 10) / 10,
        Evening:  Math.round(obj[dateKey]['evening'] * 10) / 10,
        Sleep:  Math.round(obj[dateKey]['sleep'] * 10) / 10,
        Total:  Math.round((obj[dateKey]['day'] + obj[dateKey]['evening'] + obj[dateKey]['sleep']) * 10) / 10
      }
      return day
    })
    this.setState({
      formattedData: days.slice(0, 14),
      dataLoaded: true
    })
  }

  render() {
    if (this.state.dataLoaded) {
      let users = this.props.users || []
      let user = find(users.results, ["user_id", parseInt(this.props.userId)]) || {}
      let goal = user.prescribed_hours
      return (
        <ResponsiveContainer width='100%' aspect={4.0/1.0}>
          <BarChart
            data={this.state.formattedData}
            margin={{
              top: 20, right: 30, left: 20, bottom: 5,
            }}
          >
            <XAxis dataKey="name" />
            <YAxis domain={[0, 24]} tickCount={7} fontSize={12}/>
            <Tooltip />
            <Legend />
            <Bar barSize={25} dataKey="Day" stackId="a" fill="#fbab1b" label="Day" />
            <Bar dataKey="Evening" stackId="a" fill="#00b2ee" label="Evening"/>
            <Bar dataKey="Sleep" stackId="a" fill="#014e7b" label="Sleep" />
            <Bar dataKey="Total" fill="green" label="Total" radius={[20, 20, 0, 0]}/>
            {goal ? <ReferenceLine y={goal} label="Goal" stroke="green" strokeDasharray="3 3" /> : null }
          </BarChart>
        </ResponsiveContainer>
      );
    } else {
      return <div>Loading...</div>
    }
  }
}
