import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import API from './api'
import { setAuth, clearAuth } from './httpHelper.js'
import { push } from "connected-react-router";
import { showLoading } from "../utils/helpers";
import { getClubFootDaily, getClubFootWeekly, getClubFootMonthly } from '../actions/clubFoot.js'
import { getScoliosisDaily, getScoliosisWeekly, getScoliosisMonthly } from '../actions/scoliosis.js'

import {
  RESET_APP,
  LOGIN_V2,
  LOGIN_V2_SUCCEEDED,
  LOGIN_V2_FAILED,
  LOGIN_V1,
  LOGIN_V1_SUCCEEDED,
  LOGIN_V1_FAILED,
  VALIDATE_2FA,
  VALIDATE_2FA_SUCCEEDED,
  VALIDATE_2FA_FAILED,
  LOGOUT,
  LOGOUT_SUCCEEDED,
  LOGOUT_FAILED,
  GET_PROFILE,
  GET_PROFILE_SUCCEEDED,
  GET_PROFILE_FAILED,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCEEDED,
  UPDATE_PROFILE_FAILED,
  EMAIL_VERIFICATION,
  EMAIL_VERIFICATION_SUCCEEDED,
  EMAIL_VERIFICATION_FAILED,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCEEDED,
  RESET_PASSWORD_FAILED,
  SET_PASSWORD,
  SET_PASSWORD_SUCCEEDED,
  SET_PASSWORD_FAILED,
  GET_ADMINS,
  GET_ADMINS_SUCCEEDED,
  GET_ADMINS_FAILED,
  GET_ADMIN,
  GET_ADMIN_SUCCEEDED,
  GET_ADMIN_FAILED,
  UPDATE_ADMIN,
  UPDATE_ADMIN_SUCCEEDED,
  UPDATE_ADMIN_FAILED,
  ADD_ADMIN,
  ADD_ADMIN_SUCCEEDED,
  ADD_ADMIN_FAILED,
  DELETE_ADMIN,
  DELETE_ADMIN_SUCCEEDED,
  DELETE_ADMIN_FAILED,
  RESET_ADMIN_PASSWORD,
  RESET_ADMIN_PASSWORD_SUCCEEDED,
  RESET_ADMIN_PASSWORD_FAILED,
  GET_DASHBOARD,
  GET_DASHBOARD_SUCCEEDED,
  GET_DASHBOARD_FAILED,
  EXPORT_USERS,
  EXPORT_USERS_SUCCEEDED,
  EXPORT_USERS_FAILED,
  GET_CLUBFOOT,
  GET_CLUBFOOT_SUCCEEDED,
  GET_CLUBFOOT_FAILED,
  GET_CLUBFOOT_USER,
  GET_CLUBFOOT_USER_SUCCEEDED,
  GET_CLUBFOOT_USER_FAILED,
  GET_CLUBFOOT_DAILY,
  GET_CLUBFOOT_DAILY_SUCCEEDED,
  GET_CLUBFOOT_DAILY_FAILED,
  GET_CLUBFOOT_WEEKLY,
  GET_CLUBFOOT_WEEKLY_SUCCEEDED,
  GET_CLUBFOOT_WEEKLY_FAILED,
  GET_CLUBFOOT_MONTHLY,
  GET_CLUBFOOT_MONTHLY_SUCCEEDED,
  GET_CLUBFOOT_MONTHLY_FAILED,
  UPDATE_CLUBFOOT_USER,
  UPDATE_CLUBFOOT_USER_SUCCEEDED,
  UPDATE_CLUBFOOT_USER_FAILED,
  DELETE_CLUBFOOT_USER,
  DELETE_CLUBFOOT_USER_SUCCEEDED,
  DELETE_CLUBFOOT_USER_FAILED,
  GET_SCOLIOSIS,
  GET_SCOLIOSIS_SUCCEEDED,
  GET_SCOLIOSIS_FAILED,
  GET_SCOLIOSIS_USER,
  GET_SCOLIOSIS_USER_SUCCEEDED,
  GET_SCOLIOSIS_USER_FAILED,
  GET_SCOLIOSIS_DAILY,
  GET_SCOLIOSIS_DAILY_SUCCEEDED,
  GET_SCOLIOSIS_DAILY_FAILED,
  GET_SCOLIOSIS_WEEKLY,
  GET_SCOLIOSIS_WEEKLY_SUCCEEDED,
  GET_SCOLIOSIS_WEEKLY_FAILED,
  GET_SCOLIOSIS_MONTHLY,
  GET_SCOLIOSIS_MONTHLY_SUCCEEDED,
  GET_SCOLIOSIS_MONTHLY_FAILED,
  UPDATE_SCOLIOSIS_USER,
  UPDATE_SCOLIOSIS_USER_SUCCEEDED,
  UPDATE_SCOLIOSIS_USER_FAILED,
  DELETE_SCOLIOSIS_USER,
  DELETE_SCOLIOSIS_USER_SUCCEEDED,
  DELETE_SCOLIOSIS_USER_FAILED
} from '../actions/actionTypes.js'

export function* loginV2Request(action) {
  try {
    const response = yield call(API.loginV2, action);
    yield put({
      type: LOGIN_V2_SUCCEEDED,
      tempToken: response.Response.temp_token,
      frameContent: response.Response.frame_content,
    })
  } catch (err) {
    yield put({ 
      type: LOGIN_V2_FAILED, 
      error: "Sorry, something went wrong. Please try again.",
      err: err 
    });
  }
};

export function* validate2FARequest(action) {
  try {
    const response = yield call(API.validate2FA, action);
    setAuth(response.key);
    yield put({
      type: VALIDATE_2FA_SUCCEEDED,
      token: response.key
    })
  } catch (err) {
    yield put({ 
      type: VALIDATE_2FA_FAILED, 
      error: "Two-Factor Authentication failed. Please try again." });
  }
}


export function* loginV1Request(action) {
  try {
    const response = yield call(API.loginV1, action);
    setAuth(response.key);
    yield put({
      type: LOGIN_V1_SUCCEEDED,
      token: response.key
    })
  } catch (err) {
    yield put({ 
      type: LOGIN_V1_FAILED, 
      error: "Sorry, something went wrong. Please try again.",
      err: err 
     });
  }
};

export function* logoutRequest(action) {  // No actual logout endpoint?
  yield put({ type: RESET_APP });
  clearAuth();
  yield put(push('/login'));
};

export function* getProfileAndRerouteRequest(action) {
  try {
    const response = yield call(API.getProfile, action);
    yield put({
      type: GET_PROFILE_SUCCEEDED,
      data: response
    })
    yield put(push('/dashboard'));
  } catch (err) {
    console.log(err)
    yield put({ 
      type: GET_PROFILE_FAILED, 
      error: 'Sorry, something went wrong. Please try again.',
      err: err 
    });
  }
};

export function* getProfileRequest(action) {
  try {
    const response = yield call(API.getProfile, action);
    yield put({
      type: GET_PROFILE_SUCCEEDED,
      data: response
    })
  } catch (err) {
    console.log(err)
    yield put({ 
      type: GET_PROFILE_FAILED, 
      error: 'Sorry, something went wrong. Please try again.',
      err: err 
    });
  }
};

export function* updateProfileRequest(action) {
  try {
    const response = yield call(API.updateProfile, action);
    yield put({
      type: UPDATE_PROFILE_SUCCEEDED,
      data: response
    })
    yield put(push('/dashboard'));
  } catch (err) {
    yield put({ type: UPDATE_PROFILE_FAILED, error: err });
  }
};

export function* resetPasswordRequest(action) {
  try {
    const response = yield call(API.resetPassword, action);
    if (response.Success) {
      yield put({
        type: RESET_PASSWORD_SUCCEEDED,
        data: response
      })
    } else {
      yield put({
        type: RESET_PASSWORD_FAILED,
        error: 'Sorry, something went wrong. Please try again.',
      })
    }
  } catch (err) {
    yield put({ type: RESET_PASSWORD_FAILED, error: err });
  }
};

export function* setPasswordRequest(action) {
  console.log('3')
  try {
    setAuth(action.payload.token);
    const response = yield call(API.setPassword, action);
    if (response.Success) {
      yield put({
        type: SET_PASSWORD_SUCCEEDED,
        data: response
      })
    } else {
      yield put({
        type: SET_PASSWORD_FAILED,
        error: 'Sorry, something went wrong. Please try again.',
      })
    }
  } catch (err) {
    yield put({ type: SET_PASSWORD_FAILED, error: err });
  }
};

export function* getAdminsRequest(action) {
  try {
    const response = yield call(API.getAdmins, action);
    yield put({
      type: GET_ADMINS_SUCCEEDED,
      data: response
    })
  } catch (err) {
    yield put({ type: GET_ADMINS_FAILED, error: err});
  }
};

export function* getAdminRequest(action) {
  showLoading('Loading');
  try {
    const response = yield call(API.getAdmin, action);
    yield put({
      type: GET_ADMIN_SUCCEEDED,
      data: response
    })
  } catch (err) {
    yield put({ type: GET_ADMIN_FAILED, error: err});
  }
};

export function* updateAdminRequest(action) {
  try {
    const response = yield call(API.updateAdmin, action);
    yield put({
      type: UPDATE_ADMIN_SUCCEEDED,
      data: response
    })
  } catch (err) {
    yield put({ type: UPDATE_ADMIN_FAILED, error: err});
  }
};

export function* addAdminRequest(action) {
  try {
    const response = yield call(API.addAdmin, action);
    yield put({
      type: ADD_ADMIN_SUCCEEDED,
      data: response
    })
  } catch (err) {
    yield put({ type: ADD_ADMIN_FAILED, error: err});
  }
};

export function* deleteAdminRequest(action) {
  try {
    const response = yield call(API.deleteAdmin, action);
    yield put({
      type: DELETE_ADMIN_SUCCEEDED,
      data: response
    })
    yield put(push('/admins'));    
  } catch (err) {
    yield put({ type: DELETE_ADMIN_FAILED, error: err});
  }
};

export function* resetAdminPasswordRequest(action) {
  try {
    const response = yield call(API.resetAdminPassword, action);
    yield put({
      type: RESET_ADMIN_PASSWORD_SUCCEEDED,
      data: response
    })
  } catch (err) {
    yield put({ type: RESET_ADMIN_PASSWORD_FAILED, error: err });
  }
};

export function* getDashboardRequest(action) {
  try {
    const response = yield call(API.getDashboard, action);
    yield put({
      type: GET_DASHBOARD_SUCCEEDED,
      data: response
    });
  } catch (err) {
    yield put({ type: GET_DASHBOARD_FAILED, error: err});
  }
};

export function* exportUsersRequest(action) {
  try {
    const response = yield call(API.exportUsers, action);
    const data = new Blob([response], {type: 'application/pdf'});
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    let date = new Date().toLocaleDateString()
    let dateString = date.replace(/\//g, '-')
    link.setAttribute('download', `${dateString}-export.pdf`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    yield put({
      type: EXPORT_USERS_SUCCEEDED,
      data: response
    });
  } catch (err) {
    yield put({ type: EXPORT_USERS_FAILED, error: err});
  }
};

export function* getClubFootUsersRequest(action) {
  try {
    const response = yield call(API.getClubFootUsers, action);
    yield put({
      type: GET_CLUBFOOT_SUCCEEDED,
      data: response
    });
  } catch (err) {
    yield put({ type: GET_CLUBFOOT_FAILED, error: err });
  }
};

export function* getClubFootUserDetailsRequest(action) {
  showLoading('Loading');
  try {
    const response = yield call(API.getClubFootUserDetails, action);
    yield put({
      type: GET_CLUBFOOT_USER_SUCCEEDED,
      data: response
    });
    yield put(getClubFootDaily(action.payload.id))
    yield put(getClubFootWeekly(action.payload.id))
    yield put(getClubFootMonthly(action.payload.id))
  } catch (err) {
    yield put({ type: GET_CLUBFOOT_USER_FAILED, error: err });
  }
};

export function* getClubFootDailyRequest(action) {
  try {
    const response = yield call(API.getClubFootDaily, action);
    yield put({
      type: GET_CLUBFOOT_DAILY_SUCCEEDED,
      data: response
    });
  } catch (err) {
    yield put({ type: GET_CLUBFOOT_DAILY_FAILED, error: err });
  }
};

export function* getClubFootWeeklyRequest(action) {
  try {
    const response = yield call(API.getClubFootWeekly, action);
    yield put({
      type: GET_CLUBFOOT_WEEKLY_SUCCEEDED,
      data: response
    });
  } catch (err) {
    yield put({ type: GET_CLUBFOOT_WEEKLY_FAILED, error: err });
  }
};

export function* getClubFootMonthlyRequest(action) {
  try {
    const response = yield call(API.getClubFootMonthly, action);
    yield put({
      type: GET_CLUBFOOT_MONTHLY_SUCCEEDED,
      data: response
    });
  } catch (err) {
    yield put({ type: GET_CLUBFOOT_MONTHLY_FAILED, error: err });
  }
};

export function* updateClubFootUserRequest(action) {
  try {
    const response = yield call(API.updateClubFootUser, action);
    yield put({
      type: UPDATE_CLUBFOOT_USER_SUCCEEDED,
      data: response
    });
  } catch (err) {
    yield put({ type: UPDATE_CLUBFOOT_USER_FAILED, error: err });
  }
};

export function* deleteClubFootUserRequest(action) {
  try {
    const response = yield call(API.deleteClubFootUser, action);
    yield put({
      type: DELETE_CLUBFOOT_USER_SUCCEEDED,
      data: response
    })
    yield put(push('/dashboard'));
  } catch (err) {
    yield put({ type: DELETE_CLUBFOOT_USER_FAILED, error: err});
  }
};

export function* getScoliosisUsersRequest(action) {
  try {
    const response = yield call(API.getScoliosisUsers, action);
    yield put({
      type: GET_SCOLIOSIS_SUCCEEDED,
      data: response
    });
  } catch (err) {
    yield put({ type: GET_SCOLIOSIS_FAILED, error: err });
  }
};

export function* getScoliosisUserDetailsRequest(action) {
  showLoading('Loading');
  try {
    const response = yield call(API.getScoliosisUserDetails, action);
    yield put({
      type: GET_SCOLIOSIS_USER_SUCCEEDED,
      data: response
    });

    let patientId = response.patient.user_id || response.parent.user_id
    yield put(getScoliosisDaily(patientId))
    yield put(getScoliosisWeekly(patientId))
    yield put(getScoliosisMonthly(patientId))
  } catch (err) {
    yield put ({ type: GET_SCOLIOSIS_USER_FAILED, error: err });
  }
}

export function* getScoliosisDailyRequest(action) {
  try {
    const response = yield call(API.getScoliosisDaily, action);
    yield put({
      type: GET_SCOLIOSIS_DAILY_SUCCEEDED,
      data: response
    });
  } catch (err) {
    yield put ({ type: GET_SCOLIOSIS_DAILY_FAILED, error: err });
  }
}

export function* getScoliosisWeeklyRequest(action) {
  try {
    const response = yield call(API.getScoliosisWeekly, action);
    yield put({
      type: GET_SCOLIOSIS_WEEKLY_SUCCEEDED,
      data: response
    });
  } catch (err) {
    yield put ({ type: GET_SCOLIOSIS_WEEKLY_FAILED, error: err });
  }
}

export function* getScoliosisMonthlyRequest(action) {
  try {
    const response = yield call(API.getScoliosisMonthly, action);
    yield put({
      type: GET_SCOLIOSIS_MONTHLY_SUCCEEDED,
      data: response
    });
  } catch (err) {
    yield put ({ type: GET_SCOLIOSIS_MONTHLY_FAILED, error: err });
  }
}

export function* updateScoliosisUserRequest(action) {
  try {
    const response = yield call(API.updateScoliosisUser, action);
    yield put({
      type: UPDATE_SCOLIOSIS_USER_SUCCEEDED,
      data: response
    });
  } catch (err) {
    yield put ({ type: UPDATE_SCOLIOSIS_USER_FAILED, error: err });
  }
}

export function* deleteScoliosisUserRequest(action) {
  try {
    const response = yield call(API.deleteScoliosisUser, action);
    yield put({
      type: DELETE_SCOLIOSIS_USER_SUCCEEDED,
      data: response
    })
    yield put(push('/dashboard'));
  } catch (err) {
    yield put({ type: DELETE_SCOLIOSIS_USER_FAILED, error: err});
  }
};


export function* rootWatcher(){
  yield takeLatest(LOGIN_V2, loginV2Request)
  yield takeLatest(VALIDATE_2FA, validate2FARequest)
  yield takeLatest(VALIDATE_2FA_SUCCEEDED, getProfileAndRerouteRequest)
  yield takeLatest(LOGIN_V1, loginV1Request)
  yield takeLatest(LOGIN_V1_SUCCEEDED, getProfileAndRerouteRequest)
  yield takeLatest(LOGOUT, logoutRequest)
  yield takeLatest(GET_PROFILE, getProfileRequest)
  yield takeLatest(UPDATE_PROFILE, updateProfileRequest)
  yield takeLatest(UPDATE_PROFILE_SUCCEEDED, getProfileRequest)
  yield takeLatest(UPDATE_PROFILE_SUCCEEDED, getAdminsRequest)
  yield takeLatest(RESET_PASSWORD, resetPasswordRequest)
  yield takeLatest(SET_PASSWORD, setPasswordRequest)
  yield takeLatest(GET_ADMINS, getAdminsRequest)
  yield takeLatest(GET_ADMIN, getAdminRequest)
  yield takeLatest(UPDATE_ADMIN, updateAdminRequest)
  yield takeLatest(ADD_ADMIN, addAdminRequest)
  yield takeLatest(ADD_ADMIN_SUCCEEDED, getAdminsRequest)
  yield takeLatest(DELETE_ADMIN, deleteAdminRequest)
  yield takeLatest(DELETE_ADMIN_SUCCEEDED, getAdminsRequest)
  yield takeLatest(RESET_ADMIN_PASSWORD, resetAdminPasswordRequest)
  yield takeLatest(GET_DASHBOARD, getDashboardRequest)
  yield takeLatest(EXPORT_USERS, exportUsersRequest)
  yield takeLatest(GET_CLUBFOOT, getClubFootUsersRequest)
  yield takeLatest(GET_CLUBFOOT_USER, getClubFootUserDetailsRequest)
  yield takeLatest(GET_CLUBFOOT_DAILY, getClubFootDailyRequest)
  yield takeLatest(GET_CLUBFOOT_WEEKLY, getClubFootWeeklyRequest)
  yield takeLatest(GET_CLUBFOOT_MONTHLY, getClubFootMonthlyRequest)
  yield takeLatest(UPDATE_CLUBFOOT_USER, updateClubFootUserRequest)
  yield takeLatest(UPDATE_CLUBFOOT_USER_SUCCEEDED, getClubFootUsersRequest)
  yield takeLatest(UPDATE_CLUBFOOT_USER_SUCCEEDED, getDashboardRequest)
  yield takeLatest(DELETE_CLUBFOOT_USER, deleteClubFootUserRequest)
  yield takeLatest(DELETE_CLUBFOOT_USER_SUCCEEDED, getDashboardRequest)
  yield takeLatest(DELETE_CLUBFOOT_USER_SUCCEEDED, getClubFootUsersRequest)
  yield takeLatest(GET_SCOLIOSIS, getScoliosisUsersRequest)
  yield takeLatest(GET_SCOLIOSIS_USER, getScoliosisUserDetailsRequest)
  yield takeLatest(GET_SCOLIOSIS_DAILY, getScoliosisDailyRequest)
  yield takeLatest(GET_SCOLIOSIS_WEEKLY, getScoliosisWeeklyRequest)
  yield takeLatest(GET_SCOLIOSIS_MONTHLY, getScoliosisMonthlyRequest)
  yield takeLatest(UPDATE_SCOLIOSIS_USER, updateScoliosisUserRequest)
  yield takeLatest(UPDATE_SCOLIOSIS_USER_SUCCEEDED, getScoliosisUsersRequest)
  yield takeLatest(UPDATE_SCOLIOSIS_USER_SUCCEEDED, getDashboardRequest)
  yield takeLatest(DELETE_SCOLIOSIS_USER, deleteScoliosisUserRequest)
  yield takeLatest(DELETE_SCOLIOSIS_USER_SUCCEEDED, getDashboardRequest)
  yield takeLatest(DELETE_SCOLIOSIS_USER_SUCCEEDED, getScoliosisUsersRequest)
}

export default function* rootSaga() {
  yield all([
    rootWatcher(),
  ]);
}