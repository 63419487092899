import React, { useState } from "react";
import styled from "styled-components";
import pathOr from "ramda/src/pathOr";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FilledInput from "@material-ui/core/FilledInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import FormContainer from "../login/FormContainer";
import { validatePassword } from "../../utils/helpers";
import { setPassword } from "../../actions/auth";
import { palette } from "../../styles/variables";
import queryString from 'query-string'

const Message = styled.p`
  text-align: left;
  margin-bottom: 20px;
  color: ${props => props.error ? palette.red : 'inherit'};
`

const useStyles = makeStyles(theme => ({
  formControl: {
    width: "100%",
    marginBottom: "40px"
  },
  button: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: 1.14,
    letterSpacing: "1.25px",
    padding: "16px",
    width: "100%"
  }
}));

const mapStateToProps = state => ({
  loading: pathOr(false, ["auth", "loading"], state),
  errorMessage: pathOr(null, ["auth", "error"], state),
});

const mapDispatchToProps = dispatch => ({
  setPassword: (password, token) => dispatch(setPassword(password, token))
});


const SetPassword = (props) => {
  const values = queryString.parse(props.location.search)
  const classes = useStyles();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [error, setError] = useState(false);
  const [pass, setPass] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handleChange = event => {
    setDirty(false);
    setError(!validatePassword(event.target.value));
    setPass(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleSubmit = () => {
    setIsSubmitted(true);
    setDirty(true);
    if (!error) {
      props.setPassword(pass, values.token)
    }
  };

  return (
    <FormContainer title="Set New Password">
      {isSubmitted && dirty && !props.loading && <Message error={props.errorMessage}>{props.errorMessage || 'Your password has been set.'}</Message>}
      <form noValidate>
        <FormControl
          className={classes.formControl}
          variant="filled"
          error={isSubmitted && error}
        >
          <InputLabel htmlFor="adornment-password">Password</InputLabel>
            <FilledInput
              id="adornment-password"
              type={showPassword ? "text" : "password"}
              value={pass}
              onChange={handleChange}
              autoComplete="off"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          {isSubmitted && error && (
            <FormHelperText id="pass-error-text">
              Please enter a password with at least 9 characters.
            </FormHelperText>
          )}
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleSubmit}
          disabled={props.loading || (isSubmitted && error) || pass.length < 9}
        >
          SUBMIT
        </Button>
      </form>
    </FormContainer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword)
