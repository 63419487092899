import React from "react"
import styled from "styled-components"
import Tabs from "../../../components/tabs/Tabs";
import Tab from "../../../components/tabs/Tab";
import TabPanel from "../../../components/tabs/TabPanel";
import { palette } from "../../../styles/variables";
import PatientForm from "../../../components/PatientForm";
import ResetPasswordForm from "../../../components/ResetPasswordForm";
import IconSpine from "../../app/IconSpine";
import ConfirmationDialog from "../../../components/ConfirmationDialog";

const Container = styled.div`
  border-radius: 4px;
  border: solid 1px ${palette.border};
  background-color: white;
  position: relative;
`

const Label = styled.div`
  position: absolute;
  right: 40px;
  top: 25px;
  display: flex;
  align-items: center;

  svg {
    path {
      fill: rgba(0, 0, 0, 0.6);
    }
  }

  span {
    color: rgba(0, 0, 0, 0.6);
    margin-right: 14px;
    &:last-of-type {
      margin-right: 9px;
    }
  }
`

const ResetContainer = styled.div`
  margin-top: 34px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Button = styled.button`
  line-height: 1.14;
  letter-spacing: 1.25px;
  color: ${palette.gray};
  font-size: 14px;
  font-weight: 500;
  border: 0;
  cursor: pointer;
  background: transparent;
  text-transform: uppercase;
`

const InfoBox = (props) => {
  const [tabValue, setTabValue] = React.useState(0);
  const [showParentResetPassword, setShowParentResetPassword] = React.useState(false);
  const [showPatientResetPassword, setShowPatientResetPassword] = React.useState(false);
  const [showPatientDialog, setShowPatientDialog] = React.useState(false);
  const [showParentDialog, setShowParentDialog] = React.useState(false);

  function handleTabChange(event, newValue) {
    setTabValue(newValue);
  }

  const handleDelete = (userType) => {
    if (userType == "patient")
      props.deleteUser(props.user.patient.user_id)
    else if (userType="parent")
      props.deleteUser(props.user.parent.user_id)
  }

  let u = props.user.parent || props.user.parent
  if (!u) u = {}
  let d = new Date(u.created_at)

  return (
    <Container>
      <Label>
        <span style={{marginRight:"40px"}}>Created: {d.toLocaleDateString("en-US")}</span>
        <span>Scoliosis</span>
        <IconSpine />
      </Label>
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="Parent/Patient Info Box">
        {props.user.patient ? <Tab label="Patient Info" /> : null }
        <Tab label="Parent Info" />
      </Tabs>
      {props.user.patient ? <TabPanel value={tabValue} index={0}>
        <PatientForm userType="scoliosis" user={props.user.patient} updateUser={props.updateUser}/>

        <ResetContainer>
          {/* <Button type="button" onClick={() => setShowParentResetPassword(!showParentResetPassword)}>Reset Password</Button> */}
          {props.user.patient ? <Button type="button" onClick={() => setShowPatientDialog(true)}>Delete User</Button> : null }
        </ResetContainer>
        {showParentResetPassword && <ResetPasswordForm />}
      </TabPanel> : null }
      <TabPanel value={tabValue} index={1}>
        <PatientForm userType="scoliosis" user={props.user.parent} updateUser={props.updateUser}/>

        <ResetContainer>
          {/* <Button type="button" onClick={() => setShowPatientResetPassword(!showPatientResetPassword)}>Reset Password</Button> */}
          {props.user.parent ? <Button type="button" onClick={() => setShowParentDialog(true)}>Delete User</Button> : null }
        </ResetContainer>
        {showPatientResetPassword && <ResetPasswordForm />}
      </TabPanel>
      <ConfirmationDialog open={showPatientDialog} title="Delete User (Patient)" operation={() => handleDelete("patient")} onClose={() => setShowPatientDialog(false)} />
      <ConfirmationDialog open={showParentDialog} title="Delete User (Parent)" operation={() => handleDelete("parent")} onClose={() => setShowParentDialog(false)} />
    </Container>
  )
}

export default InfoBox