export const RESET_APP="RESET_APP";

// Auth Action Types
export const LOGIN_V2="LOGIN_V2";
export const LOGIN_V2_SUCCEEDED="LOGIN_V2_SUCCEEDED";
export const LOGIN_V2_FAILED="LOGIN_V2_FAILED";

export const LOGIN_V1="LOGIN_V1";
export const LOGIN_V1_SUCCEEDED="LOGIN_V1_SUCCEEDED";
export const LOGIN_V1_FAILED="LOGIN_V1_FAILED";

export const VALIDATE_2FA="VALIDATE_2FA";
export const VALIDATE_2FA_SUCCEEDED="VALIDATE_2FA_SUCCEEDED";
export const VALIDATE_2FA_FAILED="VALIDATE_2FA_FAILED";

export const LOGOUT="LOGOUT";
export const LOGOUT_SUCCEEDED="LOGOUT_SUCCEEDED";
export const LOGOUT_FAILED="LOGOUT_FAILED";

export const GET_PROFILE="GET_PROFILE";
export const GET_PROFILE_SUCCEEDED="GET_PROFILE_SUCCEEDED";
export const GET_PROFILE_FAILED="GET_PROFILE_FAILED";

export const UPDATE_PROFILE="UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCEEDED="UPDATE_PROFILE_SUCCEEDED";
export const UPDATE_PROFILE_FAILED="UPDATE_PROFILE_FAILED";

export const EMAIL_VERIFICATION="EMAIL_VERIFICATION";
export const EMAIL_VERIFICATION_SUCCEEDED="EMAIL_VERIFICATION_SUCCEEDED";
export const EMAIL_VERIFICATION_FAILED="EMAIL_VERIFICATION_FAILED";

export const RESET_PASSWORD="RESET_PASSWORD";
export const RESET_PASSWORD_SUCCEEDED="RESET_PASSWORD_SUCCEEDED";
export const RESET_PASSWORD_FAILED="RESET_PASSWORD_FAILED";

export const SET_PASSWORD="SET_PASSWORD";
export const SET_PASSWORD_SUCCEEDED="SET_PASSWORD_SUCCEEDED";
export const SET_PASSWORD_FAILED="SET_PASSWORD_FAILED";

// Admins Action Types
export const GET_ADMINS="GET_ADMINS";
export const GET_ADMINS_SUCCEEDED="GET_ADMINS_SUCCEEDED";
export const GET_ADMINS_FAILED="GET_ADMINS_FAILED";

export const GET_ADMIN="GET_ADMIN";
export const GET_ADMIN_SUCCEEDED="GET_ADMIN_SUCCEEDED";
export const GET_ADMIN_FAILED="GET_ADMIN_FAILED";

export const UPDATE_ADMIN="UPDATE_ADMIN";
export const UPDATE_ADMIN_SUCCEEDED="UPDATE_ADMIN_SUCCEEDED";
export const UPDATE_ADMIN_FAILED="UPDATE_ADMIN_FAILED";

export const ADD_ADMIN="ADD_ADMIN";
export const ADD_ADMIN_SUCCEEDED="ADD_ADMIN_SUCCEEDED";
export const ADD_ADMIN_FAILED="ADD_ADMIN_FAILED";

export const DELETE_ADMIN="DELETE_ADMIN";
export const DELETE_ADMIN_SUCCEEDED="DELETE_ADMIN_SUCCEEDED";
export const DELETE_ADMIN_FAILED="DELETE_ADMIN_FAILED";

export const RESET_ADMIN_PASSWORD="RESET_ADMIN_PASSWORD";
export const RESET_ADMIN_PASSWORD_SUCCEEDED="RESET_ADMIN_PASSWORD_SUCCEEDED";
export const RESET_ADMIN_PASSWORD_FAILED="RESET_ADMIN_PASSWORD_FAILED";

// Dashboard Action Types
export const GET_DASHBOARD="GET_DASHBOARD";
export const GET_DASHBOARD_SUCCEEDED="GET_DASHBOARD_SUCCEEDED";
export const GET_DASHBOARD_FAILED="GET_DASHBOARD_FAILED";

// ClubFoot Action Types
export const GET_CLUBFOOT="GET_CLUBFOOT";
export const GET_CLUBFOOT_SUCCEEDED="GET_CLUBFOOT_SUCCEEDED";
export const GET_CLUBFOOT_FAILED="GET_CLUBFOOT_FAILED";

export const GET_CLUBFOOT_USER="GET_CLUBFOOT_USER"
export const GET_CLUBFOOT_USER_SUCCEEDED="GET_CLUBFOOT_USER_SUCCEEDED"
export const GET_CLUBFOOT_USER_FAILED="GET_CLUBFOOT_USER_FAILED"

export const UPDATE_CLUBFOOT_USER="UPDATE_CLUBFOOT_USER"
export const UPDATE_CLUBFOOT_USER_SUCCEEDED="UPDATE_CLUBFOOT_USER_SUCCEEDED"
export const UPDATE_CLUBFOOT_USER_FAILED="UPDATE_CLUBFOOT_USER_FAILED"

export const DELETE_CLUBFOOT_USER="DELETE_CLUBFOOT_USER"
export const DELETE_CLUBFOOT_USER_SUCCEEDED="DELETE_CLUBFOOT_USER_SUCCEEDED"
export const DELETE_CLUBFOOT_USER_FAILED="DELETE_CLUBFOOT_USER_FAILED"

export const GET_CLUBFOOT_DAILY="GET_CLUBFOOT_DAILY"
export const GET_CLUBFOOT_DAILY_SUCCEEDED="GET_CLUBFOOT_DAILY_SUCCEEDED"
export const GET_CLUBFOOT_DAILY_FAILED="GET_CLUBFOOT_DAILY_FAILED"

export const GET_CLUBFOOT_WEEKLY="GET_CLUBFOOT_WEEKLY"
export const GET_CLUBFOOT_WEEKLY_SUCCEEDED="GET_CLUBFOOT_WEEKLY_SUCCEEDED"
export const GET_CLUBFOOT_WEEKLY_FAILED="GET_CLUBFOOT_WEEKLY_FAILED"

export const GET_CLUBFOOT_MONTHLY="GET_CLUBFOOT_MONTHLY"
export const GET_CLUBFOOT_MONTHLY_SUCCEEDED="GET_CLUBFOOT_MONTHLY_SUCCEEDED"
export const GET_CLUBFOOT_MONTHLY_FAILED="GET_CLUBFOOT_MONTHLY_FAILED"

// Scoliosis Action Types
export const GET_SCOLIOSIS="GET_SCOLIOSIS";
export const GET_SCOLIOSIS_SUCCEEDED="GET_SCOLIOSIS_SUCCEEDED";
export const GET_SCOLIOSIS_FAILED="GET_SCOLIOSIS_FAILED";

export const GET_SCOLIOSIS_USER="GET_SCOLIOSIS_USER"
export const GET_SCOLIOSIS_USER_SUCCEEDED="GET_SCOLIOSIS_USER_SUCCEEDED"
export const GET_SCOLIOSIS_USER_FAILED="GET_SCOLIOSIS_USER_FAILED"

export const UPDATE_SCOLIOSIS_USER="UPDATE_SCOLIOSIS_USER"
export const UPDATE_SCOLIOSIS_USER_SUCCEEDED="UPDATE_SCOLIOSIS_USER_SUCCEEDED"
export const UPDATE_SCOLIOSIS_USER_FAILED="UPDATE_SCOLIOSIS_USER_FAILED"

export const DELETE_SCOLIOSIS_USER="DELETE_SCOLIOSIS_USER"
export const DELETE_SCOLIOSIS_USER_SUCCEEDED="DELETE_SCOLIOSIS_USER_SUCCEEDED"
export const DELETE_SCOLIOSIS_USER_FAILED="DELETE_SCOLIOSIS_USER_FAILED"

export const GET_SCOLIOSIS_DAILY="GET_SCOLIOSIS_DAILY"
export const GET_SCOLIOSIS_DAILY_SUCCEEDED="GET_SCOLIOSIS_DAILY_SUCCEEDED"
export const GET_SCOLIOSIS_DAILY_FAILED="GET_SCOLIOSIS_DAILY_FAILED"

export const GET_SCOLIOSIS_WEEKLY="GET_SCOLIOSIS_WEEKLY"
export const GET_SCOLIOSIS_WEEKLY_SUCCEEDED="GET_SCOLIOSIS_WEEKLY_SUCCEEDED"
export const GET_SCOLIOSIS_WEEKLY_FAILED="GET_SCOLIOSIS_WEEKLY_FAILED"

export const GET_SCOLIOSIS_MONTHLY="GET_SCOLIOSIS_MONTHLY"
export const GET_SCOLIOSIS_MONTHLY_SUCCEEDED="GET_SCOLIOSIS_MONTHLY_SUCCEEDED"
export const GET_SCOLIOSIS_MONTHLY_FAILED="GET_SCOLIOSIS_MONTHLY_FAILED"

export const EXPORT_USERS="EXPORT_USERS"
export const EXPORT_USERS_SUCCEEDED="EXPORT_USERS_SUCCEEDED"
export const EXPORT_USERS_FAILED="EXPORT_USERS_FAILED"