import React from "react"
import pathOr from "ramda/src/pathOr";
import { connect } from "react-redux";
import PeopleIcon from '@material-ui/icons/People';
import IconBox from "../../components/IconBox";
import EnhancedTable from "./Table"

const mapStateToProps = state => ({
  scoliosis: pathOr({}, ["scoliosis", "users"], state)
});

export default connect(mapStateToProps)(({scoliosis}) => {
  let results = scoliosis.results || []
  return (
    <>
      <div>
        <IconBox
          IconComponent={<PeopleIcon />}
          value={results.length || 0}
          label="Patients"
        />
        <EnhancedTable 
          rows={scoliosis.results ? scoliosis.results.slice(0, -1) : []} 
          loading={scoliosis.loading}
        />
      </div>
    </>
  )
})
