import initState from "./initialState";
import { 
  GET_CLUBFOOT_SUCCEEDED, 
  GET_CLUBFOOT_FAILED,
  GET_CLUBFOOT_USER,
  GET_CLUBFOOT_USER_SUCCEEDED,
  GET_CLUBFOOT_USER_FAILED,
  GET_CLUBFOOT_DAILY_SUCCEEDED,
  GET_CLUBFOOT_DAILY_FAILED,
  GET_CLUBFOOT_WEEKLY_SUCCEEDED,
  GET_CLUBFOOT_WEEKLY_FAILED,
  GET_CLUBFOOT_MONTHLY_SUCCEEDED,
  GET_CLUBFOOT_MONTHLY_FAILED,
  GET_SCOLIOSIS_USER_SUCCEEDED
} from "../actions/actionTypes";
import { hideLoading } from "../utils/helpers";

const reducer = (state = initState.clubFoot, action) => {
  switch (action.type) {
    case GET_CLUBFOOT_SUCCEEDED: 
      state = {
        ...state,
        users: action.data,
        loading: false
      }
      return state;
    case GET_CLUBFOOT_FAILED:
      state = {
        ...state,
        loading: false
      }
      return state;
    case GET_CLUBFOOT_USER:
      state = {
        ...state,
        user: {}
      }
      return state;
    case GET_CLUBFOOT_USER_SUCCEEDED: 
      state = {
        ...state,
        user: action.data
      }
      hideLoading();
      return state;
    case GET_CLUBFOOT_DAILY_SUCCEEDED:
      let daily = action.data ? action.data.date : []
      state = {
        ...state,
        daily: daily
      }
      return state;
    case GET_CLUBFOOT_WEEKLY_SUCCEEDED:
      let weekly = action.data ? action.data.date : []
      state = {
        ...state,
        weekly: weekly
      }
      return state;
    case GET_CLUBFOOT_MONTHLY_SUCCEEDED:
      let monthly = action.data ? action.data.date : []
      state = {
        ...state,
        monthly: monthly
      }
      return state;
    case GET_CLUBFOOT_DAILY_FAILED:
    case GET_CLUBFOOT_WEEKLY_FAILED:
    case GET_CLUBFOOT_MONTHLY_FAILED:
      return state;
    case GET_CLUBFOOT_USER_FAILED:
      hideLoading();
      return state
    case GET_CLUBFOOT_USER:
    case GET_SCOLIOSIS_USER_SUCCEEDED:
      state = {
        ...initState.clubFoot,
        users: state.users
      }
      return state;
    default:
      return state;
  }
};

export default reducer;
