import React from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import { addAdmin } from '../../actions/admin.js'

const CheckboxContainer = styled.div`
  display: flex;
`

const useStyles = makeStyles(theme => ({
  textField: {
    width: 250, 
  },
  formControl: {
    marginTop: 16,
  },
  content: {
    overflow: 'hidden',
  }
}));

const mapDispatchToProps = dispatch => {
  return {
    addAdmin: (admin) => dispatch(addAdmin(admin)),
  }
}

export default connect(null, mapDispatchToProps)((props) => {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    firstname: '',
    lastname: '',
    email: '',
    speciality: '',
    password: '',
  });

  const handleChange = name => event => {
    let letter = ''
    if (name == "speciality") {
      if (values.speciality === "B") 
        letter = event.target.value === 'S' ? 'C' : 'S'
      else if (values.speciality === event.target.value ) 
        letter = event.target.value
      else if (values.speciality === 'S' && event.target.value === 'C' || values.speciality === 'C' && event.target.value === 'S')
        letter = 'B'
      setValues({ ...values, [name]: letter });   
    }
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSubmit = event => {
    props.operation()
    props.onClose();
  }

  return (
    <div>
      <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
        <DialogContent className={classes.content} style={{textAlign: 'center'}}>
          <span style={{paddingLeft: 50, paddingRight: 50}}>Are you sure?</span>
        </DialogContent>
        <DialogActions style={{justifyContent: 'center'}}>
          <Button onClick={props.onClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
})
