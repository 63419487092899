import initState from "./initialState";
import {
  LOGIN_V2,
  LOGIN_V2_SUCCEEDED,
  LOGIN_V2_FAILED,
  LOGIN_V1,
  LOGIN_V1_SUCCEEDED,
  LOGIN_V1_FAILED,
  VALIDATE_2FA,
  VALIDATE_2FA_SUCCEEDED,
  VALIDATE_2FA_FAILED,
  GET_PROFILE,
  GET_PROFILE_SUCCEEDED,
  GET_PROFILE_FAILED,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCEEDED,
  SET_PASSWORD,
  SET_PASSWORD_SUCCEEDED,
  SET_PASSWORD_FAILED
} from "../actions/actionTypes";

const reducer = (state = initState.auth, action) => {
  switch (action.type) {
    case LOGIN_V2: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case LOGIN_V2_SUCCEEDED: {
      return {
        ...state,
        loginSucceeded: true,
        loading: false,
        error: null,
        tempToken: action.tempToken,
        sigRequest: action.frameContent.sig_request,
        host: action.frameContent.host
      };
    }
    case LOGIN_V2_FAILED:
      return {
        ...state,
        loginSucceeded: false,
        error: 'Incorrect email or password',
        loading: false
      }
    case VALIDATE_2FA: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }
    case VALIDATE_2FA_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        error: null,
        token: action.token
      }
    }
    case VALIDATE_2FA_FAILED: {
      return {
        ...state,
        loading: false,
        error: "Incorrect One-Time Password"
      }
    }
    case LOGIN_V1: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case LOGIN_V1_SUCCEEDED: {
      return {
        ...state,
        token: action.token,
        loading: false,
        error: null
      };
    }
    case LOGIN_V1_FAILED:
      return {
        ...state,
        error: 'Incorrect email or password',
        loading: false
      }
    case GET_PROFILE:
      return {
        ...state,
        loading: true
      }
    case GET_PROFILE_SUCCEEDED: 
      return {
        ...state,
        ...action.data,
        loading: false,
        error: null
      };
    case GET_PROFILE_FAILED: 
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case RESET_PASSWORD:
      return state
    case RESET_PASSWORD_SUCCEEDED: 
      return {
        ...state,
        loading: false,
        error: null
      };
    case SET_PASSWORD:
      return {
        ...state,
        loading: true,
      }
    case SET_PASSWORD_SUCCEEDED:
      return {
        ...state,
        loading: false,
        error: null
      }
    case SET_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        error: 'An error occurred.'
      }
    case RESET_PASSWORD_FAILED:
      return state
    default:
      return state;
  }
};

export default reducer;
