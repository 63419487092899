import { 
  GET_ADMINS, 
  GET_ADMIN, 
  UPDATE_ADMIN, 
  ADD_ADMIN,
  DELETE_ADMIN,
  RESET_ADMIN_PASSWORD
} from "./actionTypes";

export function getAdmins() {
  return { type: GET_ADMINS, payload: {}}
}

export function getAdmin(id) {
  return { type: GET_ADMIN, payload: {id}}
}

export function updateAdmin(id, admin) {
  return { type: UPDATE_ADMIN, payload: {id: id, admin: admin} }
}

export function addAdmin(admin) {
  return { type: ADD_ADMIN, payload: admin}
}

export function deleteAdmin(id) {
  return { type: DELETE_ADMIN, payload: {id}}
}

export function resetAdminPassword(id, password) {
  return { type: RESET_ADMIN_PASSWORD, payload: {id, password}}
}